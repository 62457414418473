@import url('https://fonts.googleapis.com/css2?family=Lora:wght@700&display=swap');

.App {
  text-align: center;
  background-color: #fbf4ea;
  min-height:100vh;
  display:flex;
  flex-direction: column;
}
#overlays{
  display: flex;
  justify-content: center;

}
.home-container, .container{
  
  width: 650px;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  margin:0 auto;
}

.h1-title{
  color:#403F2B;
  font-size: 36px;
  font-family: Lora, sans-serif;
  margin:0;
  margin-bottom: 12px;
}
h2{
  color:#403F2B;
  font-size: 28px;
  font-family: Lora, sans-serif;
}
h3{
  color:#403F2B;
  font-size: 20px;
  font-family: Lora, sans-serif;
  margin:0;
}

p{
  color: #6f685e;
  font-family: Josefin Sans, sans-serif;
  font-size: 16px;
  display: inline-block;
  margin: 0;
  
  line-height: 20px;
}

.stageFinding img{
 display: inline-block;
 -webkit-animation: rotating 1.2s linear infinite;
 -moz-animation: rotating 1.2
 s linear infinite;
 -ms-animation: rotating 1.2s linear infinite;
 -o-animation: rotating 1.2s linear infinite;
 animation: rotating 1.2s linear infinite;
}
.stagePending img{
  opacity:0;
}
.stagePending p{
  opacity: 0.5;
}
.loadingStage p{
  font-size: 1rem!important;
}
.stageFound img{
  display: inline-block;
}


@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.active img{
  filter: grayscale(0)!important;
  opacity: 1!important;
}
.active p{
  color:#403F2B!important
}
.bump {
  animation: bump 300ms ease-out;
}

@keyframes bump {
  from{transform:scale(1)}
  60%{transform:scale(1.1)}
  to{transform:scale(1)}
}
@-webkit-keyframes bump {
  from{-webkit-transform:scale(1)}
  60%{-webkit-transform:scale(1.1)}
  to{-webkit-transform:scale(1)}
}


@media only screen and (max-width: 600px) {
  h1 {
    font-size: 30px!important;
  }
  h2 {
    font-size: 24px!important;
  }
  .home-container, .container{
    width:100vw!important;
    padding:0 8px;
    overflow: hidden;
    
  }
  .App{
    overflow-x: hidden;
  }
}