.stepList{
    grid-column-gap: 0px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}
p span{
    margin-left:8px;
    opacity: 0.75;
}
.step{
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .step{
        display: flex;
        align-items: flex-start;
    }
    input{
        margin-top: 3px;
    }

}