.recipe-input-container{
    background-color: #f7e8d5;
    border-radius: 8px;
    padding: 32px 60px;
    text-align: left;
    
    display: flex;
    flex-direction: column;
    align-content: stretch;
    flex-wrap: wrap;
}

.usp-container{
    text-align: center;
}
.usp-icon-container{
    background-color: #fbf4ea;
    border-radius: 8px;
    margin-right: 12px;
    padding: 8px;
    display: inline-block;

}

@media only screen and (max-width: 600px) {
    .recipe-input-container{
        padding: 16px 24px!important;
    }

}
