.container{
    min-height: 100vh;
    max-width: 650px;
    flex-direction: column;
    text-align: left;
    display: flex;
    margin:auto;
    padding:10vh 0;
}
.cardTitle{
    margin-bottom:12px;
}
.CardTitleContainer{
    display: flex;
    justify-content: space-between;
 
}
.actionContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.btnSecondaryIcon{
    width:fit-content;
    background-color: transparent;
    border: none;
    padding:16px;
    border-radius: 8px;  
    font-family: Lora, sans-serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin:16px 0;
    transition: 200ms ease;
    cursor: pointer;
    border: 2px solid #403F2B;
    
}
.btnSecondaryIcon:hover{
   background-color: #F7E8D5;
   
}
.btnSecondaryIcon img, .btnYellowIcon img{
    
    margin-right:8px;
    width:16px;
}

.btnYellowIcon{
    width:fit-content;
    height:fit-content;
    background-color: #F1C737;
    border: none;
    padding:16px;
    border-radius: 8px;  
    font-family: Lora, sans-serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin:0;
    transition: 200ms ease;
    cursor: pointer;
    border: none;
}
.btnYellowIcon:hover{
    background-color: #DAB228;
    
 }



.ingredientsActionContainer{
    display: flex;
    flex-direction: row;
    align-content: center;
    line-height: 20px;
    margin-bottom:12px;
   
}
.ingredientsActionContainer p,h3{
    font-size: 1.2rem;
    align-self: center;
    margin-right:12px
}
.ingredientsActionContainer button{
    line-height: 0;
    border-radius: 8px;
   border:none;
   background-color: #fbf4ea;
    padding: 12px;
    margin-right:12px;
    cursor: pointer;
    transition: 300ms ease;
}
.ingredientsActionContainer button:hover{
    background-color: #F7E8D5;
}


@media only screen and (max-width: 600px) {
    .btnYellowIcon{
        padding:12px 8px;
    }
    .btnYellowIcon img{
        width:12px;
    }
    .container{
        padding:0 8px;
        width:100%;
    }

}