
.loadingContents{
text-align: center;
}
.loadingContents p{ 
    margin-bottom: 20px;
    font-size: 1.2rem;
}
.loadingContents img{
    margin-right: 8px;
}

.loadingStage p{
    font-size: 1rem;
}
.loadingLottie{
    width:320px;
    margin:-50px auto;
}




