.savedCardList{
    margin:24px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
    row-gap: 12px;
}

@media only screen and (max-width: 600px) {
    .savedCardList{
        margin:24px 0;
        display: grid;
        grid-template-columns: 1fr
        
 
    }

}